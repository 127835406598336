<template>
  <div class="page">
    <el-form :model="formInfo" label-width="120px" :rules="rules" ref="formInfo">
      <el-card class="no-hidden">
        <template slot="header">
          <span class="title"> {{ !isEdit ? "新增" : "编辑" }}关卡单记录</span>
          <div class="button-back">
            <el-button @click="() => {
      $router.go(-1);
    }
      " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>
        <el-form-item label="关卡类型">
          <el-radio-group v-model="activeName" @input="handleTabClick" :disabled="isEdit">
            <el-radio label="all">整单关卡</el-radio>
            <el-radio label="part">部分关卡</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 整单 -->
        <div v-if="activeName == 'all'" key="all">
          <div class="card-body">
            <el-form-item label="应用类型" prop="all.app_type_shop_type_flag">
              <el-select size="small" placeholder="请选择应用类型" v-model="formInfo.all.app_type_shop_type_flag" clearable
                style="width: 200px" :disabled="isEdit">
                <el-option v-for="item in filterdouble" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
            <!-- 新增client_id -->
            <el-form-item label="客户名称" prop="all.client_name">
              <el-select size="small" placeholder="请选择客户名称" @change="customerChange" v-model="formInfo.all.client_name"
                clearable filterable style="width: 200px">
                <el-option v-for="item in customerList" :key="item.id" :value="item.name" :label="item.name" />
              </el-select>
              <!-- <el-autocomplete size="small" v-model.trim="formInfo.all.client_name"
                :fetch-suggestions="queryClientSearchAsync" placeholder="请选择客户名称" style="width: 200px"
                :maxlength="64"></el-autocomplete> -->
            </el-form-item>

            <el-form-item label="关联卡号数据" prop="all.ids">

              <el-button type="primary" size="small" style="margin-bottom: 10px" @click="saleOrderDialog.display()"
                :disabled="!formInfo.all.app_type_shop_type_flag || !formInfo.all.client_name">
                选择销售单
              </el-button>
              <order-table type="close" :orderList="orderList" :cache="true" @change="(items) => (orderList = items)" />
            </el-form-item>

            <el-form-item label="备注信息">
              <el-input size="small" type="textarea" :maxlength="200" placeholder="请输入200字以内的备注信息" rows="5"
                resize="none" v-model.trim="formInfo.all.remark" />
            </el-form-item>
          </div>

          <x-dialog :proxy="saleOrderDialog">
            <select-sale-order-list :shop_type="formInfo.all.app_type_shop_type_flag"
              :client_name="formInfo.all.client_name" type="close" @select="handleSelectItems" />
          </x-dialog>
        </div>
        <!-- 整单 -->

        <!-- 部分 -->
        <div v-if="activeName == 'part'" key="part">
          <el-form-item label="关联销售单号" prop="part.trade_sn">
            <el-input size="small" v-model.trim="formInfo.part.trade_sn" placeholder="请输入关联销售单号" clearable
              style="width: 200px" @blur="searchTradeSn" :disabled="isEdit"></el-input>
          </el-form-item>

          <el-form-item label="应用类型" prop="part.app_type_shop_type_flag">
            <el-select size="small" placeholder="请选择应用类型" v-model="formInfo.part.app_type_shop_type_flag" clearable
              disabled style="width: 200px">
              <el-option v-for="item in cardTypes" :key="item.value" :value="item.value" :label="item.label" />
            </el-select>
          </el-form-item>

          <el-form-item label="客户名称" prop="part.client_name">
            <el-select disabled size="small" placeholder="请选择客户名称" @change="customerChange"
              v-model="formInfo.all.client_id" clearable style="width: 200px">
              <el-option v-for="item in customerList" :key="item.id" :value="item.name" :label="item.name" />
            </el-select>
            <!-- <el-autocomplete size="small" disabled v-model.trim="formInfo.part.client_name"
              :fetch-suggestions="queryClientSearchAsync" placeholder="请选择客户名称" style="width: 200px"></el-autocomplete> -->
          </el-form-item>

          <div class="card-body-1">
            <el-form-item label="录入卡号">
              <div class="card-exp-area">
                <el-form-item label="所属商城">
                  <el-tooltip :content="shop_name" :disabled="(shop_name || '').length < 12" placement="top"
                    effect="light">
                    <el-select size="small" v-model="formInfo.part.shop_id" filterable clearable style="width: 200px"
                      @change="handleShopChange" @focus="handleShopFocus">
                      <el-option v-for="(item, index) in shopList" :key="index" :value="item.shop_id"
                        :label="item.shop_name" />
                    </el-select>
                  </el-tooltip>
                </el-form-item>

                <!-- 卡号上传 -->
                <card-upload :shopId="formInfo.part.shop_id" :appType="formInfo.part.app_type_shop_type_flag"
                  @change="handleChangeCardList" @submit="exportCard" />
                <!-- 卡号上传 -->
              </div>
            </el-form-item>

            <!-- 卡券信息 -->
            <card-list ref="card_list" @change="handleChangeCardList" :tradeSn="formInfo.part.trade_sn"
              :shopData="shopList" :cardData="formInfo.part.cardList" :filterColumn="filterColumn"
              :disabled="disabledColumn" :appType="formInfo.part.app_type_shop_type_flag"
              @changeCardId="handleSelectCardId" @range="changeCardRange" :orgin="true" />
            <!-- 卡券信息 -->

            <el-form-item label="备注信息">
              <el-input size="small" type="textarea" :placeholder="isEdit ? '' : '请输入200字以内的备注信息'" :maxlength="200"
                rows="5" resize="none" v-model.trim="formInfo.part.remark" />
            </el-form-item>
          </div>
        </div>
        <!-- 部分 -->

        <el-form-item align="center" style="width: 80%">
          <el-button type="primary" :disabled="saveDisabled" @click="isPermission">保存</el-button>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import mixins from "./saleOrderRefundMixins";
import * as API_Order from "@/api/order";
import { asyncRouterMap } from "@/router";
import cardList from "@/views/tools/sale-card-open/components/cardList";
import cardUpload from "@/views/tools/sale-card-open/components/cardUpload";
import selectSaleOrderList from "@/views/tools/sale-card-open/components/selectSaleOrderList";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import orderTable from "@/views/tools/sale-card-open/components/orderTable";
import { getAllCustomerListByEnterpriseId } from "@/api/settingCustomer";
export default {
  name: "editSaleCloseRecord",
  mixins: [mixins],
  components: {
    cardList,
    cardUpload,
    selectSaleOrderList,
    orderTable,
  },
  computed: {
    filterdouble () {
      let cardTypes = this.cardTypes;
      if (!this.double_choice_mall_open) {
        cardTypes = this.cardTypes.filter(item => item.value !== 2)
      }
      return cardTypes;
    }
  },
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      saleOrderDialog: $xDialog.create({
        title: "销售订单选择器",
        width: "900px",
        disableConfirm: true,
        disableCancel: true,
      }),
      activeName: this.$route.query.type || "all",
      orderList: [],
      filterColumn: ["统一折扣率设置"],
      disabledColumn: [
        "面值/价位",
        "折扣率(%)",
        "优惠金额",
        "优惠后金额",
        "统一折扣率设置",
      ],
      isEdit: !!this.$route.query.id,
      id: this.$route.query.id,
      sn: this.$route.query.sn,
      formInfo: {
        all: {
          client_name: "",
          app_type_shop_type_flag: "",
          ids: "",
          remark: "",
        },
        part: {
          trade_sn: "", // K20230420000001
          app_type_shop_type_flag: "",
          client_name: "",
          shop_id: "",
          cardList: [],
          remark: "",
        },
      },
      rules: {
        all: {
          app_type_shop_type_flag: [
            {
              required: true,
              message: "请选择应用类型",
              trigger: ["blur", "change"],
            },
          ],
          client_name: [
            {
              required: true,
              message: "请输入客户名称",
              trigger: ["blur", "change"],
            },
          ],
          ids: [
            {
              required: true,
              message: "请选择销售单",
              trigger: ["blur", "change"],
            },
          ],
        },
        part: {
          trade_sn: [
            {
              required: true,
              message: "请输入关联销售单号",
              trigger: ["blur", "change"],
            },
          ],
          app_type_shop_type_flag: [
            {
              required: true,
              message: "请选择应用类型",
              trigger: ["blur", "change"],
            },
          ],
          client_name: [
            {
              required: true,
              message: "请输入客户名称",
              trigger: ["blur", "change"],
            },
          ],
        },
      },
      saveDisabled: false,
      shopCardList: [],
      phoneError: "",
      close_sn: "",
      shop_name: "",
      restaurants: [],
      customerList: [],//客户列表
    };
  },
  activated () {
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  async mounted () {
    this.customerList = await getAllCustomerListByEnterpriseId(this.$store.getters.shopInfo.shop_id)
    API_Order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.checkPermission();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
    this.getClientList();

    // this.formInfo.part.trade_sn = "K20230420000001";
    // this.searchTradeSn();

    this.getEidtInfo();
  },
  methods: {
    customerChange (val) {//客户选择事件
      if (val) {
        const { id } = this.customerList.find(res => res.name == this.formInfo.all.client_name)
        this.formInfo.all.client_id = id;
      } else {
        this.formInfo.all.client_id = ''
      }
    },
    handleSelectCardId (row, index) { },
    createCardInfo ({ type, item, cards }) {
      let close_card_vos = [];
      for (const key in cards) {
        let shop_list = cards[key];
        let sitem = shop_list.find((s) => s.shop_id == key);
        let info = {
          shop_id: !key ? "" : +key,
          shop_name: sitem ? sitem.shop_name : "",
          close_card_sell_vos: [],
        };

        let _obj = this.groupArrayByKey(shop_list, "card_id");
        for (const key in _obj) {
          let item_list = _obj[key];
          let num_and_section = 1;
          const { card_name, card_value } = key
            ? item_list.find((c) => c.card_id == key)
            : {
              card_name: "",
              card_value: "",
            };
          let section_item = {
            card_id: !key ? "" : +key,
            card_name,
            card_value,
            close_card_sell_section_vos: [],
          };
          if (type == "num") {
            let total = item_list[0].merge_card_num;
            let pnum = item_list
              .map((d) => d.card_num)
              .reduce((a, b) => +a + +b, 0);
            section_item.change_num = total - pnum;
            num_and_section = 0;
          }
          item_list.forEach((_item) => {
            let cmp = _item.card_code_start.match(/^[a-zA-Z]+/);
            let code_prefix = cmp ? cmp[0] : "";
            let card_code_num_start = cmp
              ? _item.card_code_start.split(code_prefix)[1]
              : _item.card_code_start;
            let card_code_num_end = cmp
              ? _item.card_code_end.split(code_prefix)[1]
              : _item.card_code_end;
            let is_change = type == "num" ? 0 : +(_item === item);
            section_item.close_card_sell_section_vos.push({
              ..._item,
              code_prefix,
              card_code_num_start,
              card_code_num_end,
              num_and_section,
              is_change,
            });
          });

          info.close_card_sell_vos.push(section_item);
        }
        close_card_vos.push(info);
      }
      return close_card_vos;
    },
    changeCardRange (opt) {
      // console.log(cards)
      const { app_type_shop_type_flag, trade_sn } = this.formInfo.part;
      const close_sn = this.close_sn;
      let close_card_vos = this.createCardInfo(opt);
      let params = {
        app_type_shop_type_flag,
        trade_sn,
        close_card_vos,
        close_sn,
      };
      API_saleCard.sectionCloseCardCheckout(params)
        .then((res) => {
          if ((res.cards_key_error || []).length > 0) {
            this.checkCardRange(
              [...res.cards_key_error],

            ).then((type) => {
              if (type == 1) {
                this.formInfo.part.cardList = this.createCardList(
                  res.close_card_vos
                );
              } else {
                this.$refs.card_list.backtrack();
              }
            });
            return;
          }
          this.formInfo.part.cardList = this.createCardList(res.close_card_vos);
        })
        .catch(() => {
          this.$refs.card_list.backtrack(opt);
        });
    },
    getEidtInfo () {
      if (!this.isEdit) return;
      let oper_type = this.activeName == "all" ? 2 : 1;
      API_saleCard.closeCardDetails2({
        sn: this.sn,
        oper_type,
      }).then((res) => {
        // this.formInfo = res;
        const {
          remark,
          app_type_shop_type_flag,
          client_name,
          sn,
          shop_close_sell_dos,
          close_sell_card_dos,
        } = res;
        // console.log(res);
        this.close_sn = sn;
        if (oper_type === 2) {
          this.formInfo.all = {
            ...this.formInfo.all,
            app_type_shop_type_flag,
            client_name,
            remark,
          };
          this.$nextTick(() => {
            this.orderList = shop_close_sell_dos.map((item) => {
              item.shop_trade_sell_list = item.shop_close_sell_dos;
              return item;
            });
          });
        } else {
          close_sell_card_dos.forEach(item => {
            let cmp = item.card_code_start.match(/^[a-zA-Z]+/);
            let code_prefix = cmp ? cmp[0] : "";
            item.code_prefix = code_prefix;
            item.card_code_start_str = code_prefix ? item.card_code_start.split(code_prefix)[1] : (item.card_code_start + "")
            item.card_code_end_str = code_prefix ? item.card_code_end.split(code_prefix)[1] : (item.card_code_end + "")
          })
          this.formInfo.part = {
            trade_sn: close_sell_card_dos[0] && close_sell_card_dos[0].trade_sn,
            app_type_shop_type_flag,
            client_name,
            shop_id: "",
            cardList: close_sell_card_dos,
            remark,
          };
          this.searchShopList();
        }
      });
    },
    createCardList (close_card_vos) {
      let cardList = close_card_vos
        .map((shop) => {
          const { shop_id, shop_name } = shop;
          let cards = shop.close_card_sell_vos.map((card) => {
            const { card_id, card_name, card_value } = card;
            let items = card.close_card_sell_section_vos.map((item) => {
              const { code_prefix, card_code_num_start, card_code_num_end, card_code_num_start_str, card_code_num_end_str } =
                item;
              item.card_id = card_id || "";
              item.card_name = card_name || "";
              item.card_value = card_value || "";
              item.shop_id = shop_id || "";
              item.shop_name = shop_name || "";
              item.card_code_start =
                (code_prefix || "") + ((card_code_num_start_str || "") + "");
              item.card_code_end =
                (code_prefix || "") + ((card_code_num_end_str || "") + "");
              return item;
            });
            return [...items];
          });
          return [...cards];
        })
        .flat(3);

      return cardList;
    },
    exportCard (params) {
      const trade_sn = this.formInfo.part.trade_sn;
      const { app_type_shop_type_flag, card_code_list, shop_id } = params;
      let cards = this.$refs.card_list.getCardData();
      let close_card_vos;
      if (Object.keys(cards).length === 1 && Object.keys(cards)[0] == "") {
        close_card_vos = [];
      } else {
        close_card_vos = this.createCardInfo({ cards });
      }
      if (!this.checkCardSave(cards)) {
        return this.$message.error("请完善卡券信息");
      }
      API_saleCard.sectionCloseCardInPutCheckout({
        app_type_shop_type_flag,
        trade_sn,
        close_card_vos,
        close_in_put_card_vo: {
          trade_sn,
          app_type_shop_type_flag,
          shop_id,
          card_code: card_code_list,
        },
      }).then((res) => {
        const { close_error_card_vo, close_card_vos } = res;
        if (close_error_card_vo) {
          const { error_type, error_card_map } = close_error_card_vo;
          this.checkCardExportRules2(error_type, error_card_map, app_type_shop_type_flag);
          return;
        }
        this.formInfo.part.cardList = this.createCardList(close_card_vos);
      });
    },
    searchShopList () {
      let trade_sn = this.formInfo.part.trade_sn;
      if (!trade_sn) return this.$message.warning("关联销售单号");
      API_saleCard.queryCloseInFo({
        trade_sn,
      }).then((res) => {
        const { shop_trade_sell_list } = res;
        this.shopList = shop_trade_sell_list || [];
      });
    },
    searchTradeSn () {
      let trade_sn = this.formInfo.part.trade_sn;
      if (!trade_sn) return this.$message.warning("关联销售单号");
      API_saleCard.queryCloseInFo({
        trade_sn,
      })
        .then((res) => {
          const { shop_trade_sell_list, app_type_shop_type_flag, client_name } = res;
          this.shopList = shop_trade_sell_list || [];
          this.formInfo.part.app_type_shop_type_flag = app_type_shop_type_flag;
          this.formInfo.part.client_name = client_name;
          this.formInfo.part.cardList = [];
        })
        .catch(() => {
          this.shopList = [];
          this.formInfo.part.client_name = "";
          this.formInfo.part.shop_id = "";
        });
    },
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (![1, 2].includes(columnIndex)) {
        return {
          rowspan: row.span,
          colspan: 1,
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
    },
    // 切换
    handleTabClick () {
      this.formInfo = {
        all: {
          app_type_shop_type_flag: "",
          client_name: "",
          ids: "",
          remark: "",
        },
        part: {
          trade_sn: "",
          app_type_shop_type_flag: "",
          client_name: "",
          shop_id: "",
          cardList: [],
          remark: "",
        },
      };
      this.orderList = [];
      this.getEidtInfo();
    },
    // 获取商城的卡券列表
    getShopCardList (val) {
      API_saleCard.getShopCardList(val, this.formInfo.all.app_type_shop_type_flag == '' ? this.formInfo.part.app_type_shop_type_flag : this.formInfo.all.app_type_shop_type_flag).then((res) => {
        this.shopCardList = res.map((item) => {
          return {
            value: item.id,
            label: item.card_name,
          };
        });
      });
    },
    // 商城下拉列表change事件
    handleShopChange (val) {
      this.shop_name = this.getShopName(val);
    },
    handleShopFocus (val) {
      if (!this.formInfo.part.trade_sn || !this.formInfo.part.app_type_shop_type_flag) {
        this.shopList = [];
        return this.$message.error("请先输入正确的关联销售单号");
      }
    },
    // 判断是否有销售开卡权限
    isPermission () {
      API_Order.getShopExtAuth().then((res) => {
        if (res.open === "OPEN") {
          this.save();
        } else {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台。"
          );
          setTimeout(() => {
            this.handleNoPermission(asyncRouterMap);
          }, 2000);
        }
      });
    },
    // 保存
    save () {
      const _params = {
        // card_info_list: this.formInfo.cardList,
        ...this.formInfo[this.activeName],
      };
      // console.log(this.$refs.formInfo);
      // console.log(_params);
      // delete _params["cardList"];
      // 校验表单
      this.$refs.formInfo.validate((validate) => {
        if (!validate) return this.$message.error("请检查必填项");
        const { close_sn } = this;
        // 整单
        if (this.activeName == "all") {
          API_saleCard.closeCard({
            close_sn,
            sell_id_s: this.orderList.map((item) => item.id).join(","),
            remark: this.formInfo.all.remark,
          }).then((res) => {
            this.$message.success(this.isEdit ? "修改成功" : "添加成功");
            this.$router.go(-1);
          });
        }
        // 部分
        else {
          const { remark, trade_sn, app_type_shop_type_flag } = this.formInfo.part;
          let cards = this.$refs.card_list.getCardData();

          if (!this.checkCardSave(cards, true)) {
            return this.$message.error("请完善卡券信息");
          }
          const close_card_vos = this.createCardInfo({ cards });

          API_saleCard.sectionCloseCard({
            remark,
            trade_sn,
            app_type_shop_type_flag,
            close_card_vos,
            close_sn,
          }).then((res) => {
            this.$message.success(this.isEdit ? "修改成功" : "添加成功");
            this.$router.go(-1);
          });
        }
      });
    },
    handleChangeCardList () { },
    handleSelectItems (items) {
      if (this.orderList.length === 0) this.orderList = items;
      else {
        items.forEach((item) => {
          if (!this.orderList.some((o) => o.id == item.id)) {
            this.orderList.push(item);
          }
        });
      }
      const idSet = new Set(this.orderList.map(item => item.id));
      const uniqueData = Array.from(idSet, id => this.orderList.find(item => item.id == id));
      this.orderList = uniqueData;
      this.formInfo.all.ids = this.orderList.map((o) => o.id).join(",");
      this.saleOrderDialog.visible = false;
    },
  },
  watch: {
    orderList: {
      handler (v) {
        this.formInfo.all.ids = v.map((o) => o.id).join(",");
      },
      deep: true,
    },
    "formInfo.all.app_type_shop_type_flag": {
      handler (v) {
        this.orderList = [];
      },
      deep: true,
    },
    "formInfo.all.client_name": {
      handler (v) {
        this.orderList = [];
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";

.order-table {

  /deep/ .el-table,
  /deep/ .el-table__body-wrapper.is-scrolling-none {
    height: auto !important;
  }
}
</style>
